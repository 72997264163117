import React, {useContext} from 'react'

import styles from './press-clipping.module.css'
import {paragraph, responsiveTitle3} from '../../typography.module.css'

import BlockText from '../../block-text'

import {buildImageObj, cn} from '../../../lib/helpers'
import {imageUrlFor} from '../../../lib/image-url'
import {AppContext} from '../../../contexts/app-context'

const PressClipping = ({ title, image, _rawQuote, quoteAttribution, href }) => {
  const {showGlow} = useContext(AppContext)
  const titleClassName = showGlow ? cn(responsiveTitle3, styles.title, 'glow') : cn(responsiveTitle3, styles.title)
  const imgClassName = showGlow ? cn(styles.image, 'aura') : styles.image
  return (
    <li className={styles.wrapper}>
      <a className={styles.link} href={href} target='_blank'>
        <h3 className={titleClassName} title={title}>
          {title}
        </h3>
        {
          image && image.asset &&
            <img
              className={imgClassName}
              src={imageUrlFor(buildImageObj(image)).url()}
              alt={image.alt}
              title={title}
            />
        }
      </a>
      {
        !!_rawQuote &&
          <blockquote className={cn(paragraph, styles.blockquote)}>
            {
              !!_rawQuote &&
                <q className={cn(styles.q, 'rich-text')}>
                  <BlockText blocks={_rawQuote} />
                </q>
            }
            {
              !!quoteAttribution &&
                <cite className={styles.cite}>
                  {quoteAttribution}
                </cite>
            }
          </blockquote>
      }
    </li>
  )
}

export default PressClipping
