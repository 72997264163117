import React from 'react'
import styles from './press.module.css'
import PressClipping from './press-clipping'

const Press = ({ press = [] }) => (
  <ul className={styles.wrapper}>
    {
      press.edges.map(({ node: { id, title, publishedAt, image, _rawQuote, quoteAttribution, href } }) => (
        <PressClipping
          key={id}
          title={title}
          publishedAt={publishedAt}
          image={image}
          _rawQuote={_rawQuote}
          quoteAttribution={quoteAttribution}
          href={href}
        />
      ))
    }
  </ul>
)

export default Press
