import {graphql} from 'gatsby'
import React from 'react'

import Layout from '../containers/layout'
import Press from '../components/press'

export const query = graphql`
  query PressPageQuery {
    press: allSanityPress(
      sort: {fields: [publishedAt], order: DESC}
    ) {
      edges {
        node {
          id
          publishedAt
          image {
            asset {
              _id
            }
            alt
          }
          title
          _rawQuote
          quoteAttribution
          href
        }
      }
    }
  }
`

const PressPage = ({ data: { press } }) => (
  <Layout>
    <Press press={press} />
  </Layout>
)

export default PressPage
